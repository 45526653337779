import React, { useEffect } from 'react';
import Chart from 'chart.js';

const Revenue = (props) => {
    useEffect(() => {
        // Get the canvas context
        var ctx1 = document.getElementById(props.id).getContext('2d');
        new Chart(ctx1, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    data: props.chartData,
                    backgroundColor: '#10b759'
                }]
            },
            options: {
                maintainAspectRatio: false,
                legend: {
                    display: false,
                    labels: {
                        display: false
                    }
                },
                scales: {
                    xAxes: [{
                        display: true,
                        barPercentage: 0.5
                    }],
                    yAxes: [{
                        gridLines: {
                            color: '#ebeef3'
                        },
                        ticks: {
                            fontColor: '#8392a5',
                            fontSize: 10,
                            min: 0
                        }
                    }]
                },
                tooltips: {
                    callbacks: {
                        label: function(tooltipItem, data) {
                            return new Intl.NumberFormat().format(tooltipItem.yLabel);
                        }
                    }
                }
            }
        });
    }, [props.chartData]);

    return <div className="chart-six"><canvas id={props.id}></canvas></div>;
};

export default Revenue;
