import React from 'react';
import PrivateRoute from '../../components/common/PrivateRoute';
import {Switch, Route} from 'react-router-dom';
import PageError from "../../components/layout/PageError";
import Customer from "../../pages/authorized/customer";
import CustomerData from "../../pages/authorized/customer/Data";
import Overview from "../../pages/authorized/Overview";
import Collection from "../../pages/authorized/Collection";
import Product from "../../pages/authorized/settings/Product";
import Subscription from "../../pages/authorized/waste/Subscription";
import Zone from "../../pages/authorized/settings/Zone";
import Agent from "../../pages/authorized/Agent";
import Rate from "../../pages/authorized/Rate";
import Finance from "../../pages/authorized/Finance";
import Settlement from "../../pages/authorized/Finance/Settlement";
import Schedule from "../../pages/authorized/Schedule";
import Account from "../../pages/authorized/settings/Account";
import Transaction from "../../pages/authorized/Finance/Transaction";

const Private = () => {
    return (
        <div>
            <Switch>
                <PrivateRoute exact path="/overview" component={Overview} />
                <PrivateRoute exact path="/customer" component={Customer} />
                <PrivateRoute exact path="/collection" component={Collection} />
                <PrivateRoute exact path="/customer/:id" component={CustomerData} />
                <PrivateRoute exact path="/agent" component={Agent} />
                <PrivateRoute exact path="/finance" component={Finance} />
                <PrivateRoute exact path="/finance/settlement" component={Settlement} />
                <PrivateRoute exact path="/finance/transaction" component={Transaction} />
                <PrivateRoute exact path="/transaction" component={Transaction} />
                <PrivateRoute exact path="/rate" component={Rate} />
                <PrivateRoute exact path="/schedule" component={Schedule} />
                <PrivateRoute exact path="/waste/subscription" component={Subscription} />
                <PrivateRoute exact path="/zone" component={Zone} />
                <PrivateRoute exact path="/product" component={Product} />
                <PrivateRoute exact path="/account" component={Account} />
                <PrivateRoute exact path="/error" component={PageError} />
                <Route component={PageError} />
            </Switch>
        </div>
    )
};

export default Private;
