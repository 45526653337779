import * as XLSX from "xlsx";

const exportToExcel = (headers, data, filename) => {
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);

    const columnWidth = 23;
    worksheet['!cols'] = new Array(Object.keys(data[0]).length).fill({width: columnWidth});

    XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });

    const headerRow = 0; // Row index for the header
    for (let i = 0; i < headers.length; i++) {
        const cell = worksheet[XLSX.utils.encode_cell({ r: headerRow, c: i })];
        cell.s = {
            fill: {
                fgColor: { rgb: "FFCC00" } // Header background color
            },
            font: {
                bold: true,
                color: { rgb: "FFFFFF" }, // Header text color
                sz: 14 // Set the font size (adjust as needed)
            },
            alignment: {
                horizontal: "center", // Center-align header text
                vertical: "center"    // Center-align header text vertically
            }
        };
    }

    const currentDate = new Date().toISOString().split('.')[0].replace('T', ' ').replace(/:/g, '-');
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Subscriptions');
    const name = `${filename}-${currentDate}.xlsx`;
    return XLSX.writeFile(workbook, name);
};

export default exportToExcel;
