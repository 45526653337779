import React, {useEffect, useState} from 'react';
import 'datatables.net';
import 'datatables.net-responsive';
import {GET_COLLECTION_DATA} from '../../../api';
import $ from "jquery";
import {capitalize} from "../../../utils/capitalize";
import cogoToast from "cogo-toast";
import {getFinanceReport, getZones} from "../../../api/app";
import moment from "moment";
import formatNumber from "../../../utils/formatNumber";
import {useSelector} from "react-redux";
import DateRangePicker from "../../../components/common/DatePicker";
import exportToExcel from "../../../utils/exporter";

let selectedArea = [];
let currentDateRange = null;
let type = null;
let total = 0;

const Finance = () => {

    document.title = "Finances";

    const auth = useSelector((state) => state.auth.user);
    const token = localStorage.getItem('jwtToken');
    const [loading, setLoading] = useState(true);
    const [exportLoading, setExportLoading] = useState(false);
    const [selectedDate, setSelectedDate] = useState(false);
    const [currDateRange, setCurrDateRange] = useState(null);
    const [zones, setZones] = useState([]);
    const [subdivisions, setSubdivisions] = useState([]);
    const [areaName, setAreaName] = useState("All Zones");
    const [subdivisionName, setSubdivisionName] = useState("Select");
    const [commission, setCommission] = useState(5);

    useEffect(() => {

        let data = currentDateRange !== null ? {
            start_date: currentDateRange.start,
            end_date: currentDateRange.end
        } : {
            geometry: selectedArea?.geometry
        }

        getFinanceReport(data).then((response) => {
            if(!response.error) {
                setCommission(response.payload.commission);
                $('#total-revenue').text(formatNumber(response.payload.total_revenue));
                $('#total-monthly-revenue').text(formatNumber(response.payload.total_monthly_revenue));
                $('#total-owning').text(formatNumber(response.payload.total_owning));
                $('#total-cumulative').text(formatNumber(response.payload.total_revenue - (response.payload.commission / 100) * response.payload.total_revenue));
                $('#total-deduction').text(formatNumber(response.payload.commission / 100) * response.payload.total_revenue);
                $('#total-monthly-cumulative').text(formatNumber(response.payload.total_monthly_revenue - (response.payload.commission / 100) * response.payload.total_monthly_revenue));
                $('#total-monthly-deduction').text(formatNumber(response.payload.commission / 100) * response.payload.total_monthly_revenue);
                $('.current-date').text(response.payload.current_date);
            }
        });

    }, [currentDateRange, selectedArea?.geometry]);

    useEffect(() => {
        let table = $('#td-transaction').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Collecting Finances Report</p>'
            },
            searching: true,
            lengthMenu: [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_COLLECTION_DATA, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        collection: "waste",
                        fieldname: "type",
                        pageSize: data.start,
                        filter: currentDateRange !== null ? {
                            status: { $in: ["paid", "expired"] },
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                            "paid_date.str": {
                                $gte: currentDateRange.start,
                                $lte: currentDateRange.end
                            }
                        } : {
                            "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                            status: { $in: ["paid", "expired"] }
                        },
                        format: 'json',
                        populate: "user product address.property",
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success: function (res) {
                        let array = [];
                        total = res.total;
                        res.data.map((data) => {
                            array.push({raw: data})
                        });
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: array
                        });
                    },
                    error: function(err) {
                        cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return `${capitalize(data.user.first_name)} ${capitalize(data.user.last_name)}`;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return `<span class="tx-color-1 tx-bold">${auth.user.country.currency.symbol}${data.meta.amount*data.bin*data.total_month}</span>`;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `<span class="tx-danger tx-bold">-${auth.user.country.currency.symbol}${((commission / 100) * data.meta.amount*data.bin*data.total_month).toFixed(2)}</span>`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        return {
                            subscription : `<span class="badge badge-primary tx-white">Subscription</span>`,
                            pickup : `<span class="badge badge-secondary tx-white">Pickup</span>`
                        }[data.product.type];
                    },
                    "targets": 3
                },
                {
                    "render": function () {
                        return `<span class="badge badge-success tx-white">Paid</span>`;
                    },
                    "targets": 4
                },
                {
                    "render": function (data) {
                        return moment(data.date.str).format("Do MMM, YYYY hh:mm")
                    },
                    "targets": 5
                }
            ]
        });

        return () => {
            selectedArea = [];
            currentDateRange = null;
            total = 0;
            table.destroy();
        }

    }, []);

    useEffect(() => {
        getZones().then((response) => {
            if(!response.error) {
                setZones(response.payload);
                setLoading(false);
            }
        })
    }, []);

    const handleDateRangeSelect = (range) => {
        const formattedStartDate = $.datepicker.formatDate('dd/mm/yy', range.startDate);
        const formattedEndDate = $.datepicker.formatDate('dd/mm/yy', range.endDate);

        currentDateRange = {
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        };

        setCurrDateRange({
            start: moment(formattedStartDate, 'DD/MM/YYYY').format("YYYY/MM/DD 00:00:00"),
            end: moment(formattedEndDate, 'DD/MM/YYYY').format("YYYY/MM/DD 23:59:59")
        });

        setSelectedDate(true);

        reloadTable();
    };

    const onAreaSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setAreaName(area.name) : setAreaName("All Zones");
        area !== null ? setSubdivisions(area.subdivisions) : setSubdivisions([]);

        reloadTable();
    }

    const onSubdivisionSelected = (area)  => {
        area !== null ? selectedArea = area : selectedArea = null;
        area !== null ? setSubdivisionName(area.name) : setSubdivisionName("Select");
        reloadTable();
    }

    const exportData = () => {
        setExportLoading(true);
        $.ajax(GET_COLLECTION_DATA, {
            type: 'POST',
            headers: {
                "Authorization": token
            },
            data: {
                collection: "waste",
                fieldname: "type",
                limit: total,
                pageSize: 0,
                filter: currentDateRange !== null ? {
                    status: { $in: ["paid", "expired"] },
                    "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                    "paid_date.str": {
                        $gte: currentDateRange.start,
                        $lte: currentDateRange.end
                    }
                } : {
                    "address.location": { $geoWithin: { $geometry: selectedArea?.geometry }},
                    status: { $in: ["paid", "expired"] }
                },
                format: 'json',
                populate: "user product address.property"
            },
            success: function (res) {
                setExportLoading(false);
                const filteredData = res.data.map(item => ({
                    first_name: capitalize(item.user.first_name),
                    last_name: capitalize(item.user.last_name),
                    total_amount: item.meta.amount * item.total_month * item.bin,
                    type: capitalize(item.product.type),
                    status: capitalize(item.status)
                }));

                const headers = [
                    "First Name",
                    "Last Name",
                    "Total Amount",
                    "Type",
                    "Status"
                ];

                exportToExcel(headers, filteredData, "Finance");
            },
            error: function(err) {
                cogoToast.error( err.responseJSON['error'].details !== undefined ? err.responseJSON['error'].details[0].message : err.responseJSON['error'], {position: "top-right", hideAfter: 5});
            }
        });
    }

    const refreshTable = (e) => {
        e.preventDefault();
        setSelectedDate(false);
        setCurrDateRange(null);
        currentDateRange = null;
        $('#td-transaction').DataTable().ajax.reload(null, false);
    }

    const reloadTable = () => {
        $('#td-transaction').DataTable().ajax.reload(null, false);
    };

    return (
        <div className="content-body">
            <div className="container-fluid pd-x-0">
                <div className="d-sm-flex align-items-center justify-content-between mg-b-20 mg-lg-b-25 mg-xl-b-30">
                    <div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb breadcrumb-style1 mg-b-10">
                                <li className="breadcrumb-item active" aria-current="page">Finances</li>
                            </ol>
                        </nav>
                        <h4 className="mg-b-0 tx-spacing--1">Finances</h4>
                    </div>
                    <div className="d-none d-md-block d-flex">
                        {exportLoading ? <button disabled className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Exporting</button> : <button className="btn btn-sm pd-x-15 btn-primary btn-uppercase mg-l-5" onClick={exportData.bind()}><i className="wd-10 mg-r-5 fa fa-upload"></i> Export</button>}
                        {/*<button className="btn btn-sm pd-x-15 btn-success btn-uppercase mg-l-5 outline-none" onClick={handleShowModal}><i className="wd-10 mg-r-5 fa fa-plus"></i> Add New</button>*/}
                        {loading ? <button disabled className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none"><span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/> Collecting</button> :
                            <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-outline-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {areaName}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    <a href="#" onClick={(e) => {e.preventDefault(); onAreaSelected(null)}} className="dropdown-item">All Zones</a>
                                    {zones.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onAreaSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    })}
                                </div>
                            </span> }
                        <span className="dropdown">
                                <button className="btn btn-sm pd-x-15 btn-secondary btn-uppercase mg-l-5 outline-none dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="wd-10 mg-r-5 fa fa-globe"></i> {subdivisions?.length ? subdivisionName : "Select"}</button>
                                <div className="dropdown-menu" style={{maxHeight: 356, overflowY: "auto"}}>
                                    {subdivisions?.length ? subdivisions.map((item, index) => {
                                        return(
                                            <a href="#" key={index} onClick={(e) => {e.preventDefault(); onSubdivisionSelected(item)}} className="dropdown-item">{capitalize(item.name)}</a>
                                        )
                                    }) : <span className="dropdown-item">Select Zone First</span>}
                                </div>
                            </span>
                    </div>
                </div>

                <div className="row row-xs">
                    <div className="col-sm-6 col-lg-4">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Revenue</h6>
                                <div className="d-flex align-items-baseline">
                                    <h3 className="tx-normal tx-rubik tx-spacing--2 lh-2 mg-b-0">{auth.user.country.currency.symbol}<span id="total-revenue">0</span></h3>
                                    <div className="d-flex justify-content-between align-items-baseline w-100">
                                        <h6 className="tx-11 tx-lg-16 tx-normal tx-rubik tx-success mg-l-5 lh-2 mg-b-0">{auth.user.country.currency.symbol}<span id="total-cumulative">0</span></h6>
                                        <h6 className="tx-11 tx-lg-16 tx-normal tx-rubik tx-danger mg-l-5 lh-2 mg-b-0">-{auth.user.country.currency.symbol}<span id="total-deduction">0</span><span>({commission}%)</span></h6>
                                    </div>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Cumulative Revenue.</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span
                                    className="current-date">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-lg-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-primary tx-semibold mg-b-8">Total Monthly Revenue</h6>
                                <div className="d-flex align-items-baseline">
                                    <h3 className="tx-normal tx-rubik tx-spacing--2 lh-2 mg-b-0">{auth.user.country.currency.symbol}<span id="total-monthly-revenue">0</span></h3>
                                    <div className="d-flex justify-content-between align-items-baseline w-100">
                                        <h6 className="tx-11 tx-lg-16 tx-normal tx-rubik tx-success mg-l-5 lh-2 mg-b-0">{auth.user.country.currency.symbol}<span id="total-monthly-cumulative">0</span></h6>
                                        <h6 className="tx-11 tx-lg-16 tx-normal tx-rubik tx-danger mg-l-5 lh-2 mg-b-0">-{auth.user.country.currency.symbol}<span id="total-monthly-deduction">0</span><span>({commission}%)</span></h6>
                                    </div>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Revenue of Current Month</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date_">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-4 mg-t-10 mg-sm-t-0">
                        <div className="card">
                            <div className="card-body">
                                <h6 className="tx-uppercase tx-11 tx-spacing-1 tx-danger tx-semibold mg-b-8">Total Owning</h6>
                                <div className="d-flex d-lg-block d-xl-flex align-items-end">
                                    <h3 className="tx-normal tx-rubik mg-b-0 mg-r-5 lh-1">K<span id="total-owning">0</span></h3>
                                </div>
                                <span id="date-current" className="tx-11 tx-color-02 mg-b-0 mg-t-5">Total Amount of Customer Owing</span>
                            </div>
                            <div className="card-footer py-0 pb-0">
                                <p className="tx-color-03 pt-3"><strong>Current Date:</strong> <span className="current-date_">{moment().format("MMM, YYYY")}</span></p>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-12 mg-t-10">
                        <div className="card">
                            <div className="card-header d-flex align-items-center justify-content-between">
                                <h6 className="mg-b-0">Transactions</h6>
                                <div className="d-flex tx-18">
                                    <div className="tx-12 tx-color-03 align-items-center d-none d-sm-flex mg-r-15">
                                        <a href="javascript:void(0)" className="link-01 tx-spacing-1 tx-rubik d-flex align-items-center" onClick={() => $(`#dateRangePicker`).datepicker('show')}>
                                            <DateRangePicker id="dateRangePicker" onDateRangeSelect={handleDateRangeSelect} />
                                            {selectedDate ?
                                                <div>
                                                    <span id="start_date">{moment(currDateRange?.start).format('DD/MM/YYYY')}</span>
                                                    <span className="mg-x-10">to</span>
                                                    <span id="end_date">{moment(currDateRange?.end).format('DD/MM/YYYY')}</span>
                                                </div> : <span>Select Date Range</span>}
                                            <ion-icon name="chevron-down-outline" aria-label="chevron down outline" role="img" className="md hydrated"></ion-icon>
                                        </a>
                                    </div>
                                    <a href="#" onClick={refreshTable.bind()} className="link-03 lh-0"><i className="icon ion-md-refresh"></i></a>
                                </div>
                            </div>
                            <div className="card-body">
                                <table id="td-transaction" className="table">
                                    <thead>
                                    <tr>
                                        <th className="wd-15p">Customer</th>
                                        <th className="wd-15p">Amount</th>
                                        <th className="wd-15p">Service Fee</th>
                                        <th className="wd-15p">Type</th>
                                        <th className="wd-15p">Status</th>
                                        <th className="wd-15p">Date</th>
                                    </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalConfirm" tabIndex="-1" role="dialog" aria-labelledby="confirmation"
                 aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm" role="document">
                    <div className="modal-content tx-14">
                        <div className="modal-header">
                            <h6 className="modal-title" id="exampleModalLabel6">Delete Confirm?</h6>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p className="mg-b-0 data-message">Empty</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" id="delete-data" className="btn btn-success btn-block">Yes, Delete
                            </button>
                            <button type="button" className="btn btn-danger btn-block mt-0"
                                    data-dismiss="modal">Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="modalReport" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-body pd-sm-b-40 pd-sm-x-30">

                            <div className="media-body">
                                <a href="" role="button" className="close pos-absolute t-15 r-15" data-dismiss="modal"
                                   aria-label="Close"><span aria-hidden="true">×</span></a>
                                <h4 className="tx-18 tx-sm-20 mg-b-10">Transaction</h4>
                            </div>

                            <ul className="nav nav-line nav-fill" id="myTab5" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" id="info-tab5" data-toggle="tab" href="#info" role="tab" aria-controls="home" aria-selected="true">Payment</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="customer-tab5" data-toggle="tab" href="#customer" role="tab" aria-controls="profile" aria-selected="false">Customer</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" id="waste-tab5" data-toggle="tab" href="#waste" role="tab" aria-controls="contact" aria-selected="false">Waste</a>
                                </li>
                            </ul>

                            <div className="tab-content mg-t-20" id="myTabContent5">
                                <div className="tab-pane fade show active" id="info" role="tabpanel" aria-labelledby="info-tab5">
                                    <p><strong>Transaction #:</strong> <span id="tx-transaction-number">---</span></p>
                                    <p><strong>Gateway:</strong> <span id="tx-gateway">---</span></p>
                                    <p><strong>Amount:</strong> ZMW<span id="tx-amount">---</span></p>
                                    <p><strong>Status:</strong> <span id="tx-status">---</span></p>
                                    <p><strong>Report Date:</strong> <span id="tx-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="customer" role="tabpanel" aria-labelledby="customer-tab5">
                                    <p><strong>Full Name:</strong> <span id="tx-full-name" className="text-capitalize">---</span></p>
                                    <p><strong>Mobile Number:</strong> <span id="tx-mobile-number">---</span></p>
                                    <p><strong>Gender:</strong> <span id="tx-gender" className="text-capitalize">---</span></p>
                                    <p><strong># of Addresses:</strong> <span id="tx-address">---</span></p>
                                    <p><strong>Joined Date:</strong> <span id="tx-joined-date">---</span></p>
                                </div>
                                <div className="tab-pane fade" id="waste" role="tabpanel" aria-labelledby="waste-tab5">
                                    <p><strong>Product:</strong> <span id="tx-product" className="text-capitalize">---</span></p>
                                    <p><strong>Property:</strong> <span id="tx-property" className="text-capitalize">---</span></p>
                                    <p><strong>Collector:</strong> <span id="tx-collector">---</span></p>
                                    <p><strong>Location:</strong> <span id="tx-location">---</span></p>
                                    <p><strong>Expiry Date:</strong> <span id="tx-period">---</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default Finance;
